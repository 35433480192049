<template>
  <div>
    
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            クーポン一覧
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            一般ユーザーが獲得した宿探しで使えるクーポンの状況を示すこと。
            <div class="bd-example">
              <li class="h6">現在有効のクーポンを示すこと</li>
              <li class="h6">クーポンアイテムの詳細を確認すること</li>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>

export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
    };
  },
  computed: {

  },
  methods: {

  },
  beforeMount() {
  },
};
</script>
